import mmcAjax from "@/common/js/mmcAjax"
export default {
    submitProj(projObj) {
        let url = `${window.APIBasePath}mmc/project/edit`
        console.log(mmcAjax, mmcAjax.post, 33)
        return mmcAjax({
            method: 'post',
            url,
            data: projObj
        })
    },

    getProj(projuuid) {
        let url = `${window.APIBasePath}mmc/project/info/${projuuid}`

        return mmcAjax({
            method: 'get',
            url,
        })
    }
}