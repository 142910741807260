<template>
    <div :disabled="disabled" class="mmc-btn-big" v-on="$listeners">
        <slot>{{ text }}</slot>
    </div>
</template>

<script>
export default {
    name: 'mmc-btn-big',

    data() {
        return {};
    },
    props: {
        text: {
            type: String,
            default: '确定',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.mmc-btn-big {
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    
    line-height: 50px;
    text-align: center;
    background: linear-gradient(to right, #40b9ff, #009bff); /* 标准的语法 */
    z-index: 2;
}
</style>
