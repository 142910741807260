<template>
    <div class="project-wrap" v-if="dataComplete">
        <div v-if="projObj.rejected_reason" class="reject-tips">
            驳回原因：{{projObj.rejected_reason}}
        </div>
        <div class="page_title">编辑</div>
        <section>
            <div>
                <div class="input_text_box">
                    <input
                        class="input_text"
                        autocapitalize="off"
                        autocorrect="off"
                        pattern="[1-9][0-9]{0,6}"
                        onkeyup='this.value=this.value.replace(/^[0]+|[^0-9]/g,"")'
                        onafterpaste='this.value=this.value.replace(/^[0]+|[^0-9]/g,"")'
                        type="tel"
                        placeholder="目标金额（必填）"
                        maxlength="7"
                        @change="changeAmount"
                        @input="form_change"
                        @blur="moneyCheck"
                        v-model="projObj.raisefunds_number"
                    />
                    <span>元</span>
                </div>
                <span
                    class="error-tips"
                    v-show="errorMsgDisplay.raisefunds_number"
                    >请输入合理的目标金额</span
                >
                <div class="second_title">标题（必填）</div>
                <div class="input_text_box">
                    <textarea
                        class="input_text biaoti_height"
                        type="text"
                        rows="2"
                        maxlength="40"
                        placeholder="填写筹款标题"
                        v-model="projObj.title"
                        @blur="titleCheck"
                        @input="form_change"
                    ></textarea>
                </div>
                <span class="error-tips" v-show="errorMsgDisplay.title"
                    >请输入4-40个字符</span
                >
            </div>
            <div>
                <div class="second_title">描述（必填）</div>
                <div class="textarea_text_box">
                    <textarea
                        class=""
                        placeholder="填写你的筹款内容(20-2000字)"
                        v-model="projObj.introduction"
                        maxlength="2000"
                        @blur="miaoShuCheck"
                        @change="form_change"
                        @input="form_change"
                        rows="5"
                    ></textarea>
                </div>
            </div>
            <span class="error-tips" v-show="errorMsgDisplay.introduction">{{
                tipsIntroduction
            }}</span>
            <div>
                <div class="second_title">上传图片（必填）</div>
                <div class="second_title-tip">
                    您可上传宠物生病前后的照片，以及生病确诊的相关医疗材料照片，如诊断证明、收费票据、病历、检查报告等，最多可传8张
                </div>
                <mmcUploaderDefault
                    class="mt20"
                    @changebegin="onPoorImgChangeStart"
                    @changeend="onPoorImgChange"
                    :maxNum="8"
                    :imgArr="projObj.images"
                ></mmcUploaderDefault>
            </div>
            <div  v-show="false">
                <div>
                    <div class="second_title">筹款顾问手机号（选填）</div>
                    <div class="input_text_box">
                        <input
                            class="input_text"
                            type="tel"
                            placeholder="填写推荐人手机号"
                            v-model="projObj.referee_phone"
                            @change="form_change"
                            @blur="phoneCheck"
                            maxlength="11"
                            disabled="true"
                        />
                    </div>
                </div>
                <span class="error-tips" v-show="errorMsgDisplay.referee_phone"
                    >请输入正确的手机号码</span
                >
            </div>
            <div v-show="false">
                <div class="second_title">
                    您的专属筹款顾问-{{ counselor.name }}
                </div>
                <div class="guwen_text_box">
                    <a :href="'tel:' + counselor.phone">{{
                        counselor.phone
                    }}
                    （有问题可随时咨询TA）</a>
                </div>
            </div>
            <div class="cheng-nuo">
                <div>我承诺：</div>
                <p>
                    1.
                    所发起的宠物救助项目，全部求助款项将用于宠物救助，绝不挪用，如有剩余及时退还。
                </p>
                <p>
                    2.
                    所发布的求助信息完整、准确、真实、及时、合法，无虚构或隐瞒情形。
                </p>
                <p>
                    3.
                    将尊重赠与人的意愿，严格按照平台要求，及时说明筹集资金的使用状况等平台要求说明的情况，并公示相应证明材料。
                </p>
                <p>若有违反，承担全部法律责任，并赔偿相关方所有损失。</p>
            </div>
            <p class="user-agreement">
                <!-- <i class="reading-clause icon-checkbox-sm"></i> -->
                <span>
                    提交即代表您已阅读并同意
                    <span class="initiation-clause" @click="showZCModal"
                        >《用户注册协议》</span
                    >及
                    <span class="letter-commitment" @click="showXYModal"
                        >《隐私政策》</span
                    >
                    <span class="letter-commitment" @click="showSMModal"
                        >《个人求助服务协议》</span
                    >
                </span>
            </p>
        </section>

        <div class="footer-fixed">
            <mmcButton class="footer_box" @click="submitProj"> 提交 </mmcButton>
        </div>

        <mmcModalSimple
            :show.sync="SMModalDisplay"
            :confirmText="'已完整阅读并同意'"
        >
            <modalSM />
        </mmcModalSimple>
        <mmcModalSimple :show.sync="XYModalDisplay" :confirmText="'我知道了'">
            <modalXY />
        </mmcModalSimple>
        <mmcModalSimple :show.sync="ZCModalDisplay" :confirmText="'我知道了'">
            <modalZC />
        </mmcModalSimple>
    </div>
</template>

<script>
import mmcUploaderDefault from '@/components/mmcUI/mmc-uploader-default';
import mmcModalSimple from '@/components/mmcUI/mmc-modal-simple';
import mmcButton from '@/components/mmcUI/mmc-btn-big';
import modalSM from '../shuoming-data-cpt/data-shuoming';
import modalXY from '../shuoming-data-cpt/data-xieyi';
import modalZC from '../shuoming-data-cpt/data-zhuce';
import storePublish from './js/store-publish';
import { mapState } from 'vuex';
import tool from './js/tool';
import alertMsg from '../../../common/js/alert-msg';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import { showLoading } from '../../../common/js/modalloading';
export default {
    data() {
        return {
            SMModalDisplay: false,
            XYModalDisplay: false,
            ZCModalDisplay: false,
            tempImgs: [],

            isHeight: true,
            minHeight: 0,

            errorMsgDisplay: {
                title: false,
                raisefunds_number: false,
                introduction: false,
                referee_phone: false,
                introduction: false,
                images:false,
            },
            tipsIntroduction: '',
            uping:false,
        };
    },
    store: storePublish,
    computed: {
        ...mapState({
            projObj: (state) => state.projObj,
            counselor: (state) => state.counselor,
            dataComplete: (state) => state.dataComplete,
        }),
    },
    methods: {
        submitProj() {
            if(this.uping){
                alertMsg('图片上传中，请等待图片上传完毕再提交');
                return;
            }
            this.phoneCheck(1);
            this.miaoShuCheck(1);
            this.titleCheck(1);
            this.moneyCheck(1);
            this.imageCheck(1);

            const { title, introduction } = this.projObj;

            if (/^\d{1,}$/.test(title.trim())) {
                alertMsg('项目标题不能是纯数字');
                return;
            } else if (/^\d{1,}$/.test(introduction.trim())) {
                alertMsg('项目描述不能是纯数字');
                return;
            }
            if (
                !this.errorMsgDisplay.title &&
                !this.errorMsgDisplay.raisefunds_number &&
                !this.errorMsgDisplay.introduction &&
                !this.errorMsgDisplay.referee_phone &&
                !this.errorMsgDisplay.introduction&&
                !this.errorMsgDisplay.images
            ) {
                showLoading('提交中');
                this.$store.dispatch('submitProj', this.$router);
            } else {
                // alertMsg('请正确填写表单内容');
            }
        },
        showSMModal() {
            this.SMModalDisplay = true;
        },
        showXYModal() {
            this.XYModalDisplay = true;
        },
        showZCModal() {
            this.ZCModalDisplay = true;
        },
        changeAmount() {
            console.log(11);
        },
        onPoorImgChange(arr) {
            this.uping = false
            this.$store.commit('changeImage', arr);
        },
        onPoorImgChangeStart(arr) {
            this.uping = true
            
        },
        moneyCheck(t) {
            this.$store.commit('changeState');
            const { raisefunds_number } = this.projObj;
            if (
                !raisefunds_number ||
                isNaN(raisefunds_number) ||
                raisefunds_number < 1
            ) {
                this.errorMsgDisplay.raisefunds_number = true;
                if (t == 1) {
                    alertMsg('请输入合理的目标金额');
                }
                return;
            } else if (raisefunds_number > 9990000) {
                this.errorMsgDisplay.raisefunds_number = true;
                if (t == 1) {
                    alertMsg('请输入合理的目标金额');
                }
                return;
            } else {
                this.errorMsgDisplay.raisefunds_number = false;
            }
        },
        titleCheck(t) {
            this.$store.commit('changeState');
            const { title } = this.projObj;

            if (!tool.checkTitle(title)) {
                if (t == 1) {
                    alertMsg('请输入4-40个字符的标题内容');
                }
                this.errorMsgDisplay.title = true;
            } else {
                this.errorMsgDisplay.title = false;
            }
        },
        imageCheck(t) {
            this.$store.commit('changeState');
            const { images } = this.projObj;
            
            if (images.length == 0) {
                if (t == 1) {
                    alertMsg('请上传图片');
                }
                this.errorMsgDisplay.images = true;
            } else {
                this.errorMsgDisplay.images = false;
            }
        },
        phoneCheck(t) {
            this.$store.commit('changeState');
            const { referee_phone } = this.projObj;
            if (referee_phone && !tool.checkPhone(referee_phone)) {
                if (t == 1) {
                    alertMsg('请输入正确的手机号码');
                }
                this.errorMsgDisplay.referee_phone = true;
            } else {
                this.errorMsgDisplay.referee_phone = false;
            }
        },
        miaoShuCheck(t) {
            this.$store.commit('changeState');
            const { introduction } = this.projObj;
            if (tool.checkIntroduction(introduction) == 0) {
                this.errorMsgDisplay.introduction = true;
                this.tipsIntroduction = '描述内容不得少于20字';
                if (t == 1) {
                    alertMsg(this.tipsIntroduction);
                }
            } else if (tool.checkIntroduction(introduction) == 1) {
                this.errorMsgDisplay.introduction = true;
                this.tipsIntroduction = '描述内容不得多于2000字';
                if (t == 1) {
                    alertMsg(this.tipsIntroduction);
                }
            } else {
                this.errorMsgDisplay.introduction = false;
                this.tipsIntroduction = '';
            }
        },
        form_change(e) {
            // this.autoTextarea();
            this.$store.commit('changeState');
        },
    },
    created() {
        authChecker.check({ notneedphone: 0 }, (res) => {
            this.$store.dispatch('initState');

            // this.dataComplete = true;
            shareCtrl.init();
        });
    },
    components: {
        mmcUploaderDefault,
        mmcModalSimple,
        mmcButton,
        modalSM,
        modalXY,
        modalZC,
    },
};
</script>

<style lang="less" scope>
.mt20 {
    margin-top: 20px;
}
.reject-tips{
    color: #FF434B;
    font-size: 12px;
    margin: 20px 0;
    background-color: #FFF2F2;
    padding: 10px;
    line-height: 1.4em;
    border-radius: 4px;
}
.project-wrap {
    text-align: left;
    padding: 0 15px 100px;

    .error-tips {
        color: #f25b4b;
        font-size: 12px;
        padding-top: 8px;
        display: block;
        text-align: right;
        // position: absolute;
    }

    .page_title {
        margin: 35px 0 40px;
        font-size: 34px;
        font-weight: 700;

        span {
            display: inline-block;
            margin: 0 10px;
        }
    }

    .second_title {
        margin: 24px 0 0;
        font-size: 17px;
        color: #333;
        font-weight: 500;
    }
    .second_title-tip {
        margin: 8px 0 0;
        color: #999;
        font-size: 12px;
        line-height: 18px;
    }

    .input_text_box {
        display: flex;
        position: relative;
        box-sizing: border-box;
        padding: 20px 0 23px;
        min-height: 30px;
        // height: 68px;
        border-bottom: 1px solid #e9e9e9;

        .input_text {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            // height: 100%;
            overflow: hidden;
            font-size: 17px;
            border: none;
            line-height: normal;
            outline: none;
            padding: 0;
            word-break: break-all;
            box-sizing: border-box;
        }
        .biaoti_height {
            min-height: 60px;
        }

        .yuan {
            font-size: 17px;
            color: #333;
            font-weight: 700;
        }
        span {
            position: absolute;
            right: 0;
            top: 76%;
            margin-top: -31.5px;
            width: 18px;
            font-size: 18px;
        }
    }

    .textarea_text_box {
        position: relative;

        box-sizing: border-box;
        overflow-x: hidden;
        border-bottom: 1px solid #e9e9e9;
        textarea {
            width: 100%;
            border: none;
            font-size: 17px;
            margin: 20px 0 23px;
            overflow-y: visible;
            outline: none;

            // min-height: 24px;
            padding: 0px;
            max-height: 300px;
        }

        .description-show {
            width: 100%;
            border: none;
            font-size: 17px;
            margin: 20px 0 23px 0;
            overflow-y: visible;
            outline: none;
        }
        .description-show:before {
            content: '填写你的筹款内容';
            font-size: 17px;
            color: #d2d2d2;
        }
        .hidePlaceholder.description-show:before {
            display: none !important;
        }
    }

    .proj-input-tip {
        margin: 15px 0 60px;
        line-height: 18px;
        text-align: justify;
    }
    .user-agreement {
        display: block;
        position: relative;
        vertical-align: baseline;
        margin: 16px 0;
        font-size: 13px;
        color: #999;
        border-top: none;
        text-align: left;
        .reading-clause {
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
            background: url('./img/check.png') no-repeat;
            background-size: 15px;
        }
    }
    .initiation-clause {
        color: #0099ff;
        cursor: pointer;
    }
    .letter-commitment {
        color: #0099ff;
        cursor: pointer;
    }

    .footer-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
    }
    .cheng-nuo {
        border-radius: 5px;
        margin-top: 16px;
        background-color: #f7f7f7;
        color: #999999;
        padding: 15px 10px;
        font-size: 12px;
        div {
            font-weight: 600;
            font-size: 13px;
        }
        p {
            margin: 10px 0;
        }
    }
}
</style>