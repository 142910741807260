import { render, staticRenderFns } from "./mmc-btn-big.vue?vue&type=template&id=99baa880&scoped=true&"
import script from "./mmc-btn-big.vue?vue&type=script&lang=js&"
export * from "./mmc-btn-big.vue?vue&type=script&lang=js&"
import style0 from "./mmc-btn-big.vue?vue&type=style&index=0&id=99baa880&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99baa880",
  null
  
)

export default component.exports