import Vuex from 'vuex'
import tool from "./tool"
import api from './api'
import { utils } from '@/common/js/mmcModule';


const storePublish = new Vuex.Store({
    state: {
        projObj: {
            uuid: "",
            "title": "",
            "images": [

            ],
            "introduction": "",
            "raisefunds_number": '',
            "referee_phone": "",
            "rejected_reason": ""
        },
        counselor: {
            name: '',
            phone: '',
            id: '',
        },
        dataComplete: false,
    },
    mutations: {

        changeImage(state, arr) {

            this.state.projObj.images = arr;
            // tool.setLocalStore(this.state.projObj)

        },
        changeState(state) {

            // tool.setLocalStore(this.state.projObj)
        }
    },
    actions: {
        submitProj(context, router) {
            tool.checkForm();
            // context.commit('increment')
            // console.log(this.state.projObj)
            let lastObj = JSON.parse(JSON.stringify(this.state.projObj));
            lastObj.raisefunds_number *= 100;
            // lastObj.raisefunds_number=0;
            // console.log(1,lastObj,22)

            api.submitProj(lastObj).then((res) => {
                // console.log(44,res,55);
                hideLoading();
                // alertMsg(res.msg);
                let projuuid = utils.getRequestParams().projuuid;
                if (res.code == 0) {
                    router.push('/fund/publish/success?projuuid=' + projuuid + '&type=edit')
                }
            })


        },
        initState() {
            let projuuid = utils.getRequestParams().projuuid;

            showLoading('加载中')
            api.getProj(projuuid).then((res) => {
                // console.log(44,res,55);
                hideLoading();
                // alertMsg(res.msg);
                if (res.code == 0) {
                    console.log(res, 4444)
                    this.state.projObj.uuid = projuuid;
                    this.state.projObj.title = res.data.title;
                    this.state.projObj.images = res.data.images;
                    this.state.projObj.introduction = res.data.introduction.replace(/(<([^>]+)>)/ig, '');
                    this.state.projObj.raisefunds_number = res.data.raisefunds_number / 100;
                    this.state.projObj.referee_phone = res.data.referee_phone;
                    this.state.projObj.rejected_reason = res.data.rejected_reason;
                    this.state.counselor.id = res.data.counselor_id;
                    this.state.counselor.phone = res.data.referee_phone;
                    this.state.counselor.name = res.data.referee_name;

                    this.state.dataComplete = true;

                }
            })

            // if (localStore) {
            //     this.state.projObj = localStore;
            // }

        },
    }
})

export default storePublish;